import { commandHandler } from '../commandHandler.js';

export function registerAboutCommand() {
    commandHandler.register('about', 'Display user information', () => {
        return {
            type: 'success',
            content: [
                {
                    type: 'muted',
                    text: `user@kurterm:~$ cat /etc/passwd | grep jonathan_kurten  
jonathan_kurten:x:1001:1001:Product Designer:/home/jonathan_kurten:/bin/bash  `
                },
                {
                    type: 'normal',
                    text: `
Jonathan Kurten is a product designer  
working at the intersection of AI and data.  

He crafts intuitive tools that turn  
complex systems into seamless experiences. 

Always exploring, always iterating.`
                },
                {
                    type: 'muted',
                    text: 'user@kurterm:~$ _'
                }
            ]
        };
    });
} 