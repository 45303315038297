// Mobile utility functions
export const MobileUtils = {
    init() {
        if (this.isMobile()) {
            this.setupScrollOptimization();
            this.setupTouchHandling();
            this.setupViewportHandling();
            this.setupAnimationOptimization();
        }
    },

    setupViewportHandling() {
        if (!window.visualViewport) return;

        let resizeTimeout;
        window.visualViewport.addEventListener('resize', () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                document.documentElement.style.setProperty(
                    '--keyboard-height',
                    `${Math.max(0, window.innerHeight - window.visualViewport.height)}px`
                );
            }, 100);
        });
    },

    setupAnimationOptimization() {
        // Use IntersectionObserver for animation triggers
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        requestAnimationFrame(() => {
                            entry.target.classList.add('visible');
                        });
                    }
                });
            },
            {
                threshold: 0.1,
                rootMargin: '20px'
            }
        );

        // Observe banner lines and other animated elements
        document.querySelectorAll('.banner-line, .ascii-banner').forEach(el => {
            observer.observe(el);
        });
    },

    setupScrollOptimization() {
        const terminal = document.querySelector('.terminal');
        if (!terminal) return;

        // Use Passive event listeners for better scroll performance
        terminal.addEventListener('touchstart', () => {
            terminal.style.willChange = 'transform';
        }, { passive: true });

        terminal.addEventListener('touchend', () => {
            requestAnimationFrame(() => {
                terminal.style.willChange = 'auto';
            });
        }, { passive: true });

        // Optimize scroll performance
        terminal.style.transform = 'translate3d(0,0,0)';
        terminal.style.backfaceVisibility = 'hidden';
    },

    scrollInputIntoView(inputElement) {
        if (!inputElement) return;

        requestAnimationFrame(() => {
            const viewport = window.visualViewport;
            if (!viewport) {
                inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return;
            }

            const inputRect = inputElement.getBoundingClientRect();
            const inputBottom = inputRect.bottom;
            const viewportBottom = viewport.height;

            if (inputBottom > viewportBottom) {
                window.scrollBy({
                    top: inputBottom - viewportBottom + 20,
                    behavior: 'smooth'
                });
            }
        });
    },

    setupTouchHandling() {
        // Prevent double-tap zoom
        let lastTouchEnd = 0;
        document.addEventListener('touchend', (event) => {
            const now = Date.now();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }
            lastTouchEnd = now;
        }, { passive: false });

        // Prevent pull-to-refresh
        document.body.style.overscrollBehavior = 'none';

        // Add touch feedback
        document.addEventListener('touchstart', (event) => {
            const target = event.target.closest('a, .command-item, .input-line');
            if (target) {
                requestAnimationFrame(() => {
                    target.style.opacity = '0.7';
                });
            }
        }, { passive: true });

        document.addEventListener('touchend', (event) => {
            const target = event.target.closest('a, .command-item, .input-line');
            if (target) {
                requestAnimationFrame(() => {
                    target.style.opacity = '';
                });
            }
        }, { passive: true });
    },

    isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
}; 