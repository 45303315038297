class CommandHandler {
  constructor() {
    this.commands = new Map();
  }

  register(name, description, handler) {
    this.commands.set(name.toLowerCase(), {
      name,
      description,
      handler
    });
  }

  async execute(command) {
    const commandName = command.trim().toLowerCase();
    const cmd = this.commands.get(commandName);

    if (!cmd) {
      return {
        type: 'error',
        content: `Command not found: ${commandName}. Type 'help' for available commands.`
      };
    }

    try {
      return await cmd.handler();
    } catch (error) {
      return {
        type: 'error',
        content: `Error executing command: ${error.message}`
      };
    }
  }

  getCommands() {
    return Array.from(this.commands.values()).map(({ name, description }) => ({
      name,
      description
    }));
  }
}

// Create and export a singleton instance
const commandHandler = new CommandHandler();
export { commandHandler }; 