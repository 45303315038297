export class TerminalAnimations {
    static async typeText(text, element, speed = 10) {
        if (!text || !element) return;

        // For ASCII art, use faster animation
        if (text.includes('█') || text.includes('▄') || text.includes('▀')) {
            return this.typeAsciiArt(text, element);
        }

        const chunks = text.match(/[\s\S]{1,4}/g) || [];
        let fullText = '';
        
        for (let i = 0; i < chunks.length; i++) {
            await new Promise(resolve => {
                requestAnimationFrame(() => {
                    fullText += chunks[i];
                    element.textContent = fullText;
                    setTimeout(resolve, speed);
                });
            });
        }
    }

    static async typeAsciiArt(text, element) {
        const lines = text.split('\n');
        let currentText = '';
        
        for (const line of lines) {
            currentText += line + '\n';
            await new Promise(resolve => {
                requestAnimationFrame(() => {
                    element.textContent = currentText;
                    setTimeout(resolve, 5);
                });
            });
        }
    }
} 