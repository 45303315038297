export class AsciiArt {
    static getBanner() {
        const banner = `
 ██ ▄█▀ █    ██  ██▀███  ▄▄▄█████▓▓█████  ██▀███   ███▄ ▄███▓
 ██▄█▒  ██  ▓██▒▓██ ▒ ██▒▓  ██▒ ▓▒▓█   ▀ ▓██ ▒ ██▒▓██▒▀█▀ ██▒
▓███▄░ ▓██  ▒██░▓██ ░▄█ ▒▒ ▓██░ ▒░▒███   ▓██ ░▄█ ▒▓██    ▓██░
▓██ █▄ ▓▓█  ░██░▒██▀▀█▄  ░ ▓██▓ ░ ▒▓█  ▄ ▒██▀▀█▄  ▒██    ▒██ 
▒██▒ █▄▒▒█████▓ ░██▓ ▒██▒  ▒██▒ ░ ░▒████▒░██▓ ▒██▒▒██▒   ░██▒
▒ ▒▒ ▓▒░▒▓▒ ▒ ▒ ░ ▒▓ ░▒▓░  ▒ ░░   ░░ ▒░ ░░ ▒▓ ░▒▓░░ ▒░   ░  ░
░ ░▒ ▒░░░▒░ ░ ░   ░▒ ░ ▒░    ░     ░ ░  ░  ░▒ ░ ▒░░  ░      ░
░ ░░ ░  ░░░ ░ ░   ░░   ░   ░         ░     ░░   ░ ░      ░   
░  ░      ░        ░               ░  ░   ░            ░   
`;
        return Promise.resolve(`<pre>${banner}</pre>`);
    }

    static getVersionText() {
        return Promise.resolve(`
v1.3.1 - Experimental shell emulation
Type 'help' to see available commands.
`);
    }

    static getSpinner() {
        return ['⠋', '⠙', '⠹', '⠸', '⠼', '⠴', '⠦', '⠧', '⠇', '⠏'];
    }

    static getLoadingBar(progress) {
        const width = 20;
        const filled = Math.floor(progress * width);
        const empty = width - filled;
        return `[${'█'.repeat(filled)}${' '.repeat(empty)}] ${Math.floor(progress * 100)}%`;
    }
} 