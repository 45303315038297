import { commandHandler } from '../commandHandler.js';

export function registerHelpCommand() {
    commandHandler.register('help', 'Display available commands', async () => {
        const commands = [
            { name: 'about', desc: 'Display information about me' },
            { name: 'contact', desc: 'Display contact information' },
            { name: 'work', desc: 'Display work experience' },
            { name: 'help', desc: 'Display this help message' },
            { name: 'clear', desc: 'Clear the terminal screen' }
        ];

        const commandList = commands
            .map(cmd => `<span style="color: #E5B387">${cmd.name}</span>${' '.repeat(15 - cmd.name.length)} - ${cmd.desc}`)
            .join('\n');

        return {
            type: 'success',
            content: [
                {
                    type: 'muted',
                    text: 'Available commands:'
                },
                {
                    type: 'info',
                    text: `${commandList}`
                }
            ]
        };
    });
} 