import { MobileUtils } from './mobile.js';
import { commandHandler } from '../commandHandler.js';

export class Autocomplete {
    constructor(input) {
        this.input = input;
        this.ghostInput = input.parentNode.querySelector('.ghost-input');
        this.ghostDescription = input.parentNode.parentNode.querySelector('.ghost-description');
        this.isMobile = MobileUtils.isMobile();
        
        // Ensure ghost input matches input styling exactly
        if (this.ghostInput) {
            this.ghostInput.style.font = window.getComputedStyle(this.input).font;
        }
        
        this.setupEventListeners();
        this.currentSuggestions = [];
        this.selectedIndex = 0;
        this.touchStartX = 0;
        this.touchStartY = 0;
    }

    setupEventListeners() {
        this.input.addEventListener('input', () => this.showSuggestion());
        
        if (this.isMobile) {
            // Touch-specific event handlers
            this.input.addEventListener('touchstart', (e) => {
                this.touchStartX = e.touches[0].clientX;
                this.touchStartY = e.touches[0].clientY;
            });

            this.input.addEventListener('touchend', (e) => {
                const touchEndX = e.changedTouches[0].clientX;
                const touchEndY = e.changedTouches[0].clientY;
                const deltaX = touchEndX - this.touchStartX;
                const deltaY = Math.abs(touchEndY - this.touchStartY);

                // If horizontal swipe (with minimal vertical movement) and there's a suggestion
                if (deltaX > 50 && deltaY < 30 && this.ghostInput.textContent) {
                    this.completeCommand();
                }
            });

            // Double tap to complete
            let lastTap = 0;
            this.input.addEventListener('touchend', (e) => {
                const currentTime = new Date().getTime();
                const tapLength = currentTime - lastTap;
                if (tapLength < 300 && tapLength > 0 && this.ghostInput.textContent) {
                    e.preventDefault();
                    this.completeCommand();
                }
                lastTap = currentTime;
            });
        }

        this.input.addEventListener('keydown', (e) => {
            switch (e.key) {
                case 'Tab':
                    e.preventDefault();
                    if (this.currentSuggestions.length > 0) {
                        if (e.shiftKey) {
                            // Cycle backwards with Shift+Tab
                            this.selectedIndex = (this.selectedIndex - 1 + this.currentSuggestions.length) % this.currentSuggestions.length;
                            this.updateGhostText();
                        } else {
                            // Complete the command if there's a suggestion
                            this.completeCommand();
                        }
                    }
                    break;
                case 'ArrowRight':
                    // Only complete if cursor is at the end
                    if (this.ghostInput.textContent && 
                        this.input.selectionStart === this.input.value.length) {
                        e.preventDefault();
                        this.completeCommand();
                    }
                    break;
                case 'Enter':
                    // Clear ghost text on enter
                    this.clearGhostText();
                    break;
            }
        });

        // Clear ghost text when clicking elsewhere
        document.addEventListener('click', (e) => {
            if (!this.input.contains(e.target)) {
                this.clearGhostText();
            }
        });
    }

    showSuggestion() {
        const inputValue = this.input.value.toLowerCase().trim();
        const commands = commandHandler.getCommands();
        
        // Only show suggestions for non-empty input
        this.currentSuggestions = inputValue === '' ? [] : commands
            .filter(cmd => cmd.name.toLowerCase().startsWith(inputValue))
            .sort((a, b) => a.name.localeCompare(b.name));

        if (this.currentSuggestions.length > 0) {
            this.selectedIndex = 0;
            this.updateGhostText();
            
            // Show command description on mobile
            if (this.isMobile && this.ghostDescription) {
                const suggestion = this.currentSuggestions[this.selectedIndex];
                this.ghostDescription.textContent = suggestion.description || '';
                this.ghostDescription.style.display = 'block';
            }
        } else {
            this.clearGhostText();
        }
    }

    updateGhostText() {
        const suggestion = this.currentSuggestions[this.selectedIndex];
        const inputValue = this.input.value;
        
        if (suggestion && suggestion.name.toLowerCase().startsWith(inputValue.toLowerCase())) {
            const completion = suggestion.name.slice(inputValue.length);
            this.ghostInput.textContent = inputValue + completion;
            this.ghostInput.style.color = 'var(--ghost-color)';
            
            // Highlight the completed part
            const ghostText = this.ghostInput.textContent;
            this.ghostInput.innerHTML = `<span style="color: transparent">${inputValue}</span>${completion}`;
        } else {
            this.clearGhostText();
        }
    }

    clearGhostText() {
        if (this.ghostInput) {
            this.ghostInput.textContent = '';
            this.ghostInput.innerHTML = '';
        }
        if (this.ghostDescription) {
            this.ghostDescription.style.display = 'none';
            this.ghostDescription.textContent = '';
        }
    }

    completeCommand() {
        if (this.ghostInput.textContent) {
            const suggestion = this.currentSuggestions[this.selectedIndex];
            if (suggestion) {
                this.input.value = suggestion.name;
                this.clearGhostText();
                this.input.setSelectionRange(this.input.value.length, this.input.value.length);
                
                // Provide haptic feedback on mobile if available
                if (this.isMobile && window.navigator.vibrate) {
                    window.navigator.vibrate(50);
                }
            }
        }
    }
} 