import './styles/style.css';
import { Terminal } from './js/terminal.js';

console.log('[Debug] Main.js loaded');

// Initialize the terminal when the DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
    console.log('[Debug] DOM Content Loaded');
    try {
        const terminal = new Terminal();
        console.log('[Debug] Terminal initialized');
    } catch (error) {
        console.error('[Debug] Error initializing terminal:', error);
        const errorContainer = document.getElementById('error-container');
        if (errorContainer) {
            errorContainer.style.color = 'red';
            errorContainer.style.padding = '20px';
            errorContainer.textContent = `Terminal initialization failed: ${error.message}`;
        }
    }
}); 