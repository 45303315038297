import { commandHandler } from '../commandHandler.js';

export function registerClearCommand() {
  commandHandler.register('clear', 'Clear the terminal screen', () => {
    // Return a special type that the terminal will handle differently
    return {
      type: 'clear',
      content: '',
      skipAnimation: true
    };
  });
} 