import { commandHandler } from '../commandHandler.js';

export function registerContactCommand() {
    commandHandler.register('contact', 'Display contact information', () => {
        return {
            type: 'success',
            content: [
                {
                    type: 'muted',
                    text: 'user@kurterm:~$ whoami'
                },
                {
                    type: 'normal',
                    text: 'jonathan_kurten\n'
                },
                {
                    type: 'muted',
                    text: 'user@kurterm:~$ echo $USER_INFO'
                },
                {
                    type: 'normal',
                    text: 'Product designer\n'
                },
                {
                    type: 'muted',
                    text: 'user@kurterm:~$ cat /etc/contacts'
                },
                {
                    type: 'normal',
                    text: `GitHub   →  <a href="https://github.com/kurtenj" target="_blank">github.com/jonathankurten</a>
LinkedIn →  <a href="https://www.linkedin.com/in/jonathankurten/" target="_blank">linkedin.com/in/jonathankurten</a>
Email    →  <a href="mailto:kurtenj@gmail.com">kurtenj@gmail.com</a>\n`
                },
                {
                    type: 'muted',
                    text: 'user@kurterm:~$ _'
                }
            ]
        };
    });
} 