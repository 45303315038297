import { AsciiArt } from './utils/ascii.js';
import { TerminalAnimations } from './utils/animations.js';
import { MobileUtils } from './utils/mobile.js';
import { Autocomplete } from './utils/autocomplete.js';
import { commandHandler } from './commandHandler.js';
import { registerHelpCommand } from './commands/help.js';
import { registerAboutCommand } from './commands/about.js';
import { registerContactCommand } from './commands/contact.js';
import { registerWorkCommand } from './commands/work.js';
import { registerClearCommand } from './commands/clear.js';

export class Terminal {
  constructor() {
    try {
      // Initialize mobile utilities first
      MobileUtils.init();

      // Register commands before DOM operations
      registerHelpCommand();
      registerAboutCommand();
      registerContactCommand();
      registerWorkCommand();
      registerClearCommand();

      // Get DOM elements
      this.outputElement = document.getElementById('terminal-output');
      this.inputElement = document.getElementById('terminal-input');
      this.inputLine = document.querySelector('.input-line');
            
      if (!this.outputElement || !this.inputElement || !this.inputLine) {
        throw new Error('Terminal DOM elements not found');
      }

      // Initialize terminal state
      this.commandHistory = [];
      this.historyIndex = -1;
      this.currentInput = '';
      this.startupSkipped = false;

      // Setup terminal features
      this.setupEventListeners();
      this.autocomplete = new Autocomplete(this.inputElement);
      this.setupScrollBehavior();

      // Start terminal
      this.initializeTerminal();
    } catch (error) {
      console.error('Terminal initialization error:', error);
      throw error;
    }
  }

  async initializeTerminal() {
    // Clear any existing content
    this.outputElement.innerHTML = '';
        
    const startupContainer = document.createElement('div');
    startupContainer.className = 'startup-sequence';
    this.outputElement.appendChild(startupContainer);

    const formatMessage = (prefix, message) => {
      const span = document.createElement('span');
      span.setAttribute('content', prefix);
      span.textContent = `${prefix} ${message}`;
      return span;
    };

    const appendMessage = async (prefix, message, className) => {
      if (this.startupSkipped) return;
      const formattedMessage = formatMessage(prefix, message);
      formattedMessage.className = className;
      formattedMessage.style.opacity = '0';
      formattedMessage.style.transform = 'translateY(10px)';
      startupContainer.appendChild(formattedMessage);
      startupContainer.appendChild(document.createElement('br'));
        
      // Force reflow
      formattedMessage.offsetHeight;
        
      // Animate in with requestAnimationFrame
      requestAnimationFrame(() => {
        formattedMessage.style.transition = 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)';
        formattedMessage.style.opacity = '1';
        formattedMessage.style.transform = 'translateY(0)';
      });
        
      await new Promise(resolve => setTimeout(resolve, 300));
    };

    // Add tab key listener for skipping
    const skipHandler = (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        if (!this.startupSkipped) {
          this.startupSkipped = true;
          document.removeEventListener('keydown', skipHandler);
          
          // Use requestAnimationFrame for smooth animation
          requestAnimationFrame(() => {
            startupContainer.classList.add('slide-out');
            setTimeout(() => {
              startupContainer.remove();
              this.displayBanner();
              this.createNewInputLine();
            }, 500);
          });
        }
      }
    };
    document.addEventListener('keydown', skipHandler);

    // System initialization sequence with proper timing
    await appendMessage('[SYS]', 'Terminal v1.0.0 initialization sequence started', 'success-text');
    await this.simulateLoading('Initializing system components', startupContainer);
    if (this.startupSkipped) return;
    await appendMessage('[OK]', 'System components initialized', 'success-text');

    await this.simulateLoading('Checking system dependencies', startupContainer);
    if (this.startupSkipped) return;
    await appendMessage('[INFO]', 'All dependencies verified', 'info-text');

    await this.simulateLoading('Configuring environment', startupContainer);
    if (this.startupSkipped) return;
    await appendMessage('[OK]', 'Environment configured', 'success-text');

    await appendMessage('[SYS]', 'Terminal initialization complete', 'success-text');
    
    // Smooth transition to banner
    if (!this.startupSkipped) {
      await new Promise(resolve => setTimeout(resolve, 800));
      requestAnimationFrame(() => {
        startupContainer.classList.add('slide-out');
        setTimeout(() => {
          startupContainer.remove();
          this.displayBanner();
          this.createNewInputLine();
        }, 500);
      });
    }
  }

  setupEventListeners() {
    // Add focus handling
    window.addEventListener('focus', () => this.focusInput());
        
    // Existing event listeners
    this.inputElement.addEventListener('keydown', async (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const command = this.inputElement.value.trim();
        if (command) {
          this.commandHistory.push(command);
          this.historyIndex = this.commandHistory.length;
          await this.executeCommand(command);
        }
        this.inputElement.value = '';
        this.currentInput = '';
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (this.historyIndex === this.commandHistory.length) {
          this.currentInput = this.inputElement.value;
        }
        if (this.historyIndex > 0) {
          this.historyIndex--;
          this.inputElement.value = this.commandHistory[this.historyIndex];
          // Move cursor to end after history navigation
          this.focusInput();
        }
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (this.historyIndex < this.commandHistory.length) {
          this.historyIndex++;
          this.inputElement.value = this.historyIndex === this.commandHistory.length
            ? this.currentInput
            : this.commandHistory[this.historyIndex];
          // Move cursor to end after history navigation
          this.focusInput();
        }
      }
    });

    // Prevent losing focus when clicking within terminal
    this.outputElement.addEventListener('click', (e) => {
      if (e.target === this.outputElement) {
        this.focusInput();
      }
    });
  }

  setupScrollBehavior() {
    const observer = new MutationObserver(() => {
      this.scrollToBottom();
    });

    observer.observe(this.outputElement, {
      childList: true,
      subtree: true,
      characterData: true
    });

    window.addEventListener('resize', () => this.scrollToBottom());
  }

  scrollToBottom() {
    this.outputElement.scrollTop = this.outputElement.scrollHeight;
    this.inputElement.scrollIntoView({ behavior: 'smooth' });
  }

  async simulateLoading(message, container) {
    const loadingText = document.createElement('span');
    loadingText.className = 'loading-text';
    container.appendChild(loadingText);
    container.appendChild(document.createElement('br'));

    const frames = AsciiArt.getSpinner();
    let frameIndex = 0;

    return new Promise(resolve => {
      const interval = setInterval(() => {
        loadingText.textContent = `${frames[frameIndex]} ${message}...`;
        frameIndex = (frameIndex + 1) % frames.length;
      }, 80);

      setTimeout(() => {
        clearInterval(interval);
        loadingText.remove();
        resolve();
      }, 800 + Math.random() * 400);
    });
  }

  async executeCommand(command) {
    console.log('Executing command:', command);
        
    // Display the command
    const commandDisplay = document.createElement('div');
    commandDisplay.className = 'command-output';
    commandDisplay.textContent = `> ${command}`;
    this.outputElement.appendChild(commandDisplay);

    // Execute the command and get result
    const result = await commandHandler.execute(command);
        
    // Handle clear command specially
    if (result.type === 'clear') {
      this.outputElement.innerHTML = '';
      return;
    }
        
    // Append the result
    await this.appendOutput(result);
        
    // Clear the input
    this.inputElement.value = '';
    this.currentInput = '';
        
    // Reset autocomplete
    if (this.autocomplete) {
      this.autocomplete.clearGhostText();
    }

    // Ensure input is focused after command execution
    this.focusInput();
  }

  async appendOutput(output) {
    console.log('Appending output:', output);
        
    if (Array.isArray(output.content)) {
      for (const part of output.content) {
        const outputDiv = document.createElement('div');
        outputDiv.className = `command-output ${part.type}-text`;
                
        const shouldAnimate = !output.skipAnimation && part.text.length < 500;
                
        if (shouldAnimate) {
          if (part.text.includes('<')) {
            await TerminalAnimations.typeText(part.text.replace(/<[^>]*>/g, ''), outputDiv, 10);
            outputDiv.innerHTML = part.text;
          } else {
            await TerminalAnimations.typeText(part.text, outputDiv, 10);
          }
        } else {
          outputDiv.innerHTML = part.text;
        }
                
        this.outputElement.appendChild(outputDiv);
      }
    } else {
      const outputDiv = document.createElement('div');
      outputDiv.className = `command-output ${output.type}-text`;
            
      const shouldAnimate = !output.skipAnimation && output.content.length < 500;
            
      if (shouldAnimate) {
        if (output.content.includes('<')) {
          await TerminalAnimations.typeText(output.content.replace(/<[^>]*>/g, ''), outputDiv, 10);
          outputDiv.innerHTML = output.content;
        } else {
          await TerminalAnimations.typeText(output.content, outputDiv, 10);
        }
      } else {
        outputDiv.innerHTML = output.content;
      }
            
      this.outputElement.appendChild(outputDiv);
    }
  }

  async displayBanner() {
    try {
      // Store existing non-banner content
      const existingContent = Array.from(this.outputElement.children);
      existingContent.forEach(el => el.remove());

      // Create banner container
      const bannerContainer = document.createElement('div');
      bannerContainer.className = 'ascii-banner';
      this.outputElement.appendChild(bannerContainer);

      // Get and display banner
      const banner = await AsciiArt.getBanner();
      const cleanBanner = banner.replace(/<\/?pre>/g, '').trim();
      const bannerLines = cleanBanner.split('\n');

      for (const line of bannerLines) {
        const lineElement = document.createElement('div');
        lineElement.className = 'banner-line';
        lineElement.textContent = line;
        bannerContainer.appendChild(lineElement);
      }

      // Create version container
      const versionContainer = document.createElement('div');
      versionContainer.className = 'version-text';
      versionContainer.textContent = 'v1.0.0';
      this.outputElement.appendChild(versionContainer);

      // Add animation classes after a short delay
      await new Promise(resolve => setTimeout(resolve, 50));
      bannerContainer.classList.add('visible');
      Array.from(bannerContainer.children).forEach((line, index) => {
        setTimeout(() => line.classList.add('visible'), index * 50);
      });

      // Restore existing content
      existingContent.forEach(el => this.outputElement.appendChild(el));

      // Show input line with animation after a short delay
      await new Promise(resolve => setTimeout(resolve, 300));
      this.inputLine.classList.add('visible');

      // Handle mobile-specific behavior
      if (MobileUtils.isMobile()) {
        requestAnimationFrame(() => {
          bannerContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
          // Setup mobile command tags after banner is displayed
          this.setupMobileCommandTags();
        });
      }

      // Ensure input is focused
      this.focusInput();
    } catch (error) {
      console.error('Error displaying banner:', error);
      // Fallback to simple text if banner fails
      const errorBanner = document.createElement('div');
      errorBanner.className = 'ascii-banner visible';
      errorBanner.textContent = 'Terminal v1.0.0';
      this.outputElement.appendChild(errorBanner);
    }
  }

  // Add a helper method for focusing input
  focusInput() {
    if (this.inputElement) {
      this.inputElement.focus();
      // Move cursor to end of input
      const length = this.inputElement.value.length;
      this.inputElement.setSelectionRange(length, length);
    }
  }

  // Update the createNewInputLine method
  createNewInputLine() {
    // Show input line if not already visible
    if (!this.inputLine.classList.contains('visible')) {
      this.inputLine.classList.add('visible');
    }
    this.focusInput();
  }

  setupMobileCommandTags() {
    // Remove any existing mobile command tags
    const existingTags = document.querySelector('.mobile-command-tags');
    if (existingTags) {
      existingTags.remove();
    }

    // Create mobile command tags container
    const mobileCommandTags = document.createElement('div');
    mobileCommandTags.className = 'mobile-command-tags';
    
    const tagsScroll = document.createElement('div');
    tagsScroll.className = 'tags-scroll';
    
    // Get available commands
    const commands = commandHandler.getCommands();
    
    // Create command tags
    commands.forEach(cmd => {
      const tag = document.createElement('button');
      tag.className = 'command-tag';
      tag.textContent = cmd.name;
      tag.addEventListener('click', async () => {
        // Execute the command
        await this.executeCommand(cmd.name);
        
        // Provide haptic feedback if available
        if (window.navigator.vibrate) {
          window.navigator.vibrate(50);
        }
      });
      tagsScroll.appendChild(tag);
    });
    
    mobileCommandTags.appendChild(tagsScroll);
    document.body.appendChild(mobileCommandTags);
    
    // Add scroll indicators
    let isScrolling = false;
    const checkScroll = () => {
      if (isScrolling) return;
      isScrolling = true;
      requestAnimationFrame(() => {
        const hasScrollLeft = tagsScroll.scrollLeft > 0;
        const hasScrollRight = tagsScroll.scrollLeft < (tagsScroll.scrollWidth - tagsScroll.clientWidth);
        mobileCommandTags.classList.toggle('has-scroll-left', hasScrollLeft);
        mobileCommandTags.classList.toggle('has-scroll-right', hasScrollRight);
        isScrolling = false;
      });
    };
    
    tagsScroll.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);
    checkScroll();
  }
} 