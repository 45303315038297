import { commandHandler } from '../commandHandler.js';

export function registerWorkCommand() {
    commandHandler.register('work', 'Display work experience', async () => {
        return {
            type: 'success',
            content: [
                {
                    type: 'muted',
                    text: 'user@kurterm:~$ cat /etc/work_experience'
                },
                {
                    type: 'info',
                    text: `Now: staff product designer / <a href="https://www.datastax.com/" target="_blank" rel="noopener noreferrer">DataStax</a>\n\nPrior: senior product designer / Ride Report`
                },
                {
                    type: 'muted',
                    text: 'user@kurterm:~$ _'
                }
            ]
        };
    });
} 